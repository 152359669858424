/* .released, .released:hover {
  background-color: rgba(0, 128, 0, 0.4) !important;
} */

/* .rejected, .rejected:hover {
  background-color: rgba(128, 0, 0, 0.4) !important;
} */

.review,
.review:hover {
  background-color: #fff !important;
}

.grey-row-bg,
.grey-row-bg:hover {
  background-color: #eaeaea !important;
}
