body {
  margin: 0px !important;
  height: 100%;
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}

html {
  height: 100%;
}

header {
  margin-bottom: 32px;
}

.root {
  height: 100%;
}

.clickable:hover {
  cursor: pointer;
}

.formBtn {
  text-transform: none;
  width: 18rem;
  height: 3rem;
  font-size: 1.5rem;
}

.disableMenuAnimation {
  background-color: transparent !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-size: 20px;
}

.risk-accordion-expanded {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.risk-accordion-expanded .risk-accordion-summary {
  font-size: 1.1rem;
}

.risk-accordion-summary {
  height: 50px;
  min-height: 50px !important;
  max-height: 50px !important;
}

.risk-accordion-expanded .risk-accordion-summary {
  background-color: rgba(0, 0, 0, 0.06);
}

.risk-accordion-expanded .risk-accordion-details {
  background-color: rgba(0, 0, 0, 0.03);
}

.leader-line {
  z-index: 12;
}

.invoice-table table {
  border-collapse: collapse;
}
.invoice-table tr {
  border: none;
}
.invoice-table th,
td {
  padding-left: 1em;
  padding-right: 1em;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

.invoice-table .first {
  border-left: 0px !important;
}

.invoice-table .last {
  border-right: 0px !important;
}

.editor-ay .rsw-ce {
  padding-right: 30px;
}
