.rsw-editor {
  border-radius: 4px;
}

.rsw-editor:hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.rsw-editor:focus {
  border-color: #32436e;
  border-width: 2px;
}

.disabled-editor .rsw-ce {
  color: rgb(172, 172, 172);
}

.disabled-editor .rsw-editor {
  border-color: rgb(172, 172, 172);
}

.error-editor .rsw-editor {
  border-color: #d32f2f;
}
